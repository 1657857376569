import React from 'react'

function NotFound() {
    return (
        <div>
            
        </div>
    )
}

export default NotFound
