import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'

const useStyles = makeStyles({
    input: {
        width: '100%'
    }
  });

function Input({value, handleChange, label, name, type="text", required=false}) {
    const classes  = useStyles()
    return (
        <>
            <TextField
                value={value}
                onChange={handleChange}
                label={label}
                name={name}
                type={type}
                variant="standard"
                className={classes.input}
                required={required}
            />
        </>
    )
}

export default Input
