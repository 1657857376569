import React, { useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Input from '../../components/Input'
import classes from './Contacts.module.css'
import { useValidate } from '../../hooks/useValidation'
import { useToaster } from '../../hooks/useToaster'
import { httpGetContacts, httpPostContact } from '../../api/utils'
import AuthProvider from '../../api/AuthProvider'
import ContactItem from '../../components/ContactItem'
import AutoComplete from '../../components/AutoComplete'
function Contacts() {

    const [newContact, setNewContact] = useState({
        name: '',
        number: ''
    })

    const [contacts, setContacts] = useState([])
    const [contactsBD, setContactsBD] = useState([])

    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [id, setId] = useState('')

    const toaster = useToaster

    const handleChangeContact = async (e) => {
        setNewContact({ ...newContact, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // проверка валидность номер телефона
        const isValidNumber = useValidate.isPhoneValid(newContact.number)
        if (!isValidNumber) {
            toaster('Не валидный номер')
            return
        }
        setLoading(true)
        setDisabled(true)
        await httpPostContact(newContact)
            .then(() => {
                getItems()
                setLoading(false)
                setDisabled(false)
                toaster('Контакт создан!', 'success')
            }).catch(err => {
                AuthProvider.checkError(err)
                setLoading(false)
                setDisabled(false)
            })
    }

    const getItems = async () => {
        setLoading(true)
        await httpGetContacts()
            .then(res => {
                setLoading(false)
                setContacts(res.data.items)
                setContactsBD(res.data.items)
            }).catch(err => {
                AuthProvider.checkError(err)
                setLoading(false)
            })
    }

    const handleKeyUp = (e) => {
        e.preventDefault()
        if(e.keyCode === 'Enter'){
            handleSubmit(e)
        }
    }

    useEffect(() => {
        getItems()
    }, [])

    const handleSearch = () => {
        if(id){
            setContacts(prev => prev.filter(item => item._id === id))
        }
    }

    const handleClear = () => {
        setContacts(contactsBD)
    }

    if (loading) {
        return <div className={classes.container}>
            Загрузка...
        </div>
    }

    return (
        <div className={classes.container}>
            <form className={classes.form} onSubmit={handleSubmit} onKeyUp={handleKeyUp}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={5}>
                        <Input
                            name="name"
                            value={newContact.name}
                            handleChange={handleChangeContact}
                            label="Введите имя"
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            name="number"
                            value={newContact.number}
                            handleChange={handleChangeContact}
                            label="Введите номер телефона"
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button type="submit" variant="contained" disabled={disabled} className={classes.button}>
                            Add Contact
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <AutoComplete contacts={contacts} setId={e => setId(e)}/>
                    </Grid>
                    <Grid item xs={1}>
                        <Button disabled={disabled} className={classes.button} onClick={handleSearch}>
                            <SearchIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button disabled={disabled} className={classes.button} onClick={handleClear}>
                            <ClearIcon />
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Grid container className={classes.contactsHead}>
                <Grid item xs={4}>
                    Имя:
                </Grid>
                <Grid item xs={5}>
                    Номер:
                </Grid>
                <Grid item xs={3} className={classes.actionHead}>
                    Действия:
                </Grid>
            </Grid>
            {
                contacts.map((contact) => (
                    <ContactItem 
                        contact={contact}
                        contacts={contacts} 
                        setContacts={e => setContacts(e)}
                        disabled={disabled} 
                        setDisabled={e => setDisabled(e)} 
                        setLoading={e => setLoading(e)}
                        key={contact._id} 
                    />
                ))
            }
        </div>
    )
}

export default Contacts
