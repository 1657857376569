import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

function AutoComplete({ contacts, setId }) {

    const defaultProps = {
        options: contacts,
        getOptionLabel: (option) => option.name,
    };

    const handleChangeAutoComplete = (event, value) => {
        if(value && value._id){
            setId(value._id)
        }
    }

    return (
        <Autocomplete
            {...defaultProps}
            id="auto-complete"
            autoComplete
            includeInputInList
            onChange={(event, value) => handleChangeAutoComplete(event, value)}
            renderInput={(params) => (
                <TextField {...params} label="Поиск по имени" variant="standard" />
            )}
        />
    );
}

export default AutoComplete;