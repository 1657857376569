import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AuthProvider from '../../api/AuthProvider'
import Input from '../../components/Input'
import { useToaster } from '../../hooks/useToaster'
import { useValidate } from '../../hooks/useValidation'
import { EDIT_AUTH } from '../../redux/types/types'
import classes from './Login.module.css'

function Login() {

    const [value, setValue] = useState({
        name: '',
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const toaster = useToaster

    const handleSubmit = (e) => {
        e.preventDefault()

        const isEmailValid = useValidate.isEmailValid(value.email)

        if(!isEmailValid){
            toaster('Не валидный email', 'error')
            return
        }

        AuthProvider.login(value)
            .then(res => {

                const currentUser = {
                    name: res.userName,
                    email: res.userEmail,
                    avatar: res.avatar,
                    id: res.userId
                }

                localStorage.setItem('isAuth', true)

                dispatch({ type: EDIT_AUTH, payload: { isAuth: true, user: currentUser, token: res.token} })
                navigate('/contacts')
            })
            .catch(err => {

            })
    }

    const handleKeyUp = (e) => {
        e.preventDefault()
        if(e.keyCode === 'Enter'){
            handleSubmit(e)
        }
    }


    return (
        <div className={classes.container}>
            <form className={classes.form} onSubmit={handleSubmit} onKeyUp={handleKeyUp}>
                <div className={classes.inputWrapper}>
                    <Input
                        value={value.name}
                        handleChange={handleChange}
                        name='name'
                        label='Name'
                        required={true}
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <Input
                        value={value.email}
                        handleChange={handleChange}
                        name='email'
                        label='Email'
                        type='email'
                        required={true}
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <Input
                        value={value.password}
                        handleChange={handleChange}
                        name='password'
                        label='Password'
                        type='password'
                        required={true}
                    />
                </div>
                <Button variant="contained" type="submit">Submit</Button>
            </form>
        </div>
    )
}

export default Login
