import React from 'react';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import { useToaster } from '../hooks/useToaster';
import AuthProvider from '../api/AuthProvider';
import { httpDeleteContact } from '../api/utils';

const useStyles = makeStyles({
    wrapper: {
        padding: '25px 10px',
        borderBottom: '1px solid #D4D4D4',
        color: '#333333'
    },
    icon: {
        pointerEvents: 'none'
    },
    actions: {
        justifyContent: 'center'
    }
});

function ContactItem({ contact, contacts, setContacts, disabled, setDisabled}) {

    const classes = useStyles()
    const navigate = useNavigate()
    const toaster = useToaster

    const handleDelete = async (id) => {
        setDisabled(true)
        await httpDeleteContact(id)
            .then(() => {
                toaster('Успешно удален!', 'success')
                setDisabled(false)
                const items = contacts.filter(item => item._id !== id)
                setContacts(items)
            })
            .catch(err => {
                AuthProvider.checkError(err)
                setDisabled(false)
            })
    }

    const EditButton = ({ id }) => {
        return (
            <Button onClick={() => navigate(`/contacts/${id}`)} disabled={disabled}>
                <EditIcon className={classes.icon} />
            </Button>
        )
    }

    const DeleteButton = ({ id }) => {
        return (
            <Button onClick={() => handleDelete(id)} disabled={disabled}>
                <DeleteIcon className={classes.icon} />
            </Button>
        )
    }

    return (
        <Grid container className={classes.wrapper}>
            <Grid item xs={4}>
                {contact.name}
            </Grid>
            <Grid item xs={5}>
                {contact.number}
            </Grid>
            <Grid container item xs={3} className={classes.actions}>
                <Grid item xs={3}>
                    <EditButton id={contact._id}/>
                </Grid>
                <Grid item xs={3}>
                    <DeleteButton id={contact._id}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ContactItem;