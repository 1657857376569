import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AuthProvider from '../api/AuthProvider';
import { httpGetByIdContact, httpPutContact } from '../api/utils';
import { useToaster } from '../hooks/useToaster';
import { useValidate } from '../hooks/useValidation';
import Input from './Input'

const useStyles = makeStyles({
    container: {
        maxWidth: '1200px',
        width: '100%',
        margin: '30px auto 20px'
    }
});

function EditContactItem() {

    const params = useParams()

    const navigate = useNavigate()

    const classes = useStyles()

    const toaster = useToaster

    const [contact, setContact] = useState({
        _id: '',
        name: '',
        number: ''
    })
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const handleChangeContact = (e) => {
        setContact({ ...contact, [e.target.name]: e.target.value })
    }

    const getItem = async () => {
        setLoading(true)
        await httpGetByIdContact(params.id)
            .then(res => {
                setContact(res.data.items)
                setLoading(false)
            })
            .catch(err => {
                AuthProvider.checkError(err)
                setLoading(false)
            })
    }

    const handleSubmit = async (e) => {

        e.preventDefault()
        // проверка валидность номер телефона
        const isValidNumber = useValidate.isPhoneValid(contact.number)
        if (!isValidNumber) {
            toaster('Не валидный номер')
            return
        }

        setDisabled(true)

        const data = {
            id: params.id,
            name: contact.name,
            number: contact.number
        }

        await httpPutContact(data)
        .then(() => {
            setDisabled(false)
            navigate('/contacts')
        }).catch(err => {
            AuthProvider.checkError(err)
            setDisabled(false)
        })
    }

    useEffect(() => {
        getItem()
    }, [])

    if (loading) {
        return <div className={classes.container}>
            Загрузка...
        </div>
    }

    return (
        <form className={classes.container} onSubmit={handleSubmit}>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={5}>
                    <Input
                        name="name"
                        value={contact.name}
                        handleChange={handleChangeContact}
                        label="Редактировать имя"
                        required={true}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Input
                        name="number"
                        value={contact.number}
                        handleChange={handleChangeContact}
                        label="Редактировать номер телефона"
                        required={true}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button type="submit" variant="contained" disabled={disabled} className={classes.button}>
                        Edit Contact
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default EditContactItem;