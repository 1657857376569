import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import useRoutes from "./hooks/useRoutes";
import 'react-toastify/dist/ReactToastify.min.css';

function App() {

  const isAuth = useSelector(state => state.auth.isAuth)

  const routes = useRoutes(isAuth)
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {routes}
    </BrowserRouter>
  );
}

export default App;
