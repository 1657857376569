import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import EditContactItem from '../components/EditContactItem'
import Contacts from '../pages/Contacts/Contacts'
import Login from '../pages/Login/Login'
import NotFound from '../pages/NotFound/NotFound'

function useRoutes( isAuth ) {

    if (!isAuth) {
        return (
            <Routes>
                <Route path={"/"} element={<Navigate to="login"/>} />
                <Route path='/login' element={<Login />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path={"/"} element={<Navigate to="contacts"/>} />
            <Route path='/contacts' element={<Contacts />} />
            <Route path='/contacts/:id' element={<EditContactItem />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default useRoutes
