export const useValidate = {
    isEmailValid: (email) => {
        const regexEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        return regexEmail.test(email)
    },
    isPhoneValid: (phoneNumber) => {
        const regexPhoneNumber = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')
        return regexPhoneNumber.test(phoneNumber)
    }
}
